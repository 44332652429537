import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSection from "../components/page-section"
import TextSection from "../components/text-section"
import Figure from "../components/figure"
import Portrait from "../components/portrait"

import DocumentUrl from "../documents/hb_painting_05-2016.pdf"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />

    <PageSection content={{ heading: 'Biography' }} >
      <TextSection>
        <p><span lang="de">Heinz Berger</span> was a painter, graphic designer and illustrator from <span lang="fr">Le Landeron</span>, Switzerland. Using acrylics, canvas, wood and paper he explored abstract painting until 2016.</p>
      </TextSection>
      <Portrait />
      <TextSection>
        <ul>
          <li>1945: born in <span lang="fr">Le Landeron</span></li>
          <li>1961 - 65: training at school of arts <span lang="de">Biel</span>/<span lang="fr">Bienne</span></li>
          <li>1969 - 76: residence in Paris</li>
          <li>from 1976: residence in Munich</li>
          <li>2016: died in Munich</li>
        </ul>
      </TextSection>
    </PageSection>

    <PageSection content={{ heading: 'Painting' }} >
      <Figure content={{ figcaption: 'Untitled, 1998, 150cm x 150cm, acrylic on canvas' }} options={{ lightOnDark: false }} >
        <Link to="/painting/" className="b-figure__link" title="Painting">
          <Img fluid={props.data.imagePnt058.childImageSharp.fluid} alt="Painting nr. 058: Untitled" />
        </Link>
      </Figure>
      <TextSection>
        <p>In the early 1990s Heinz Berger moved his focus entirely towards painting. A catalogue of his work was published by <span lang="de">NeuSEHland</span> in 1998. <a href="https://kunstraum-in.de" lang="de">Kunstraum</a> art gallery still provides access to remaining copies and to several of his artworks. If you are interested in more, check out the documentation of the remaining works in 2016.</p>
        <a href={DocumentUrl} className="b-text-section__button" target="_blank" rel="noopener noreferrer">Paintings (PDF, 2.8mb)</a>
      </TextSection>
    </PageSection>

    <PageSection content={{ heading: 'Illustration' }} >
      <Figure content={{ figcaption: 'Untitled, approx. 1984' }} options={{ lightOnDark: false }} >
        <Link to="/illustration/" className="b-figure__link" title="Illustration">
          <Img fluid={props.data.imageIll004.childImageSharp.fluid} alt="Illustration: „Kick“" />
        </Link>
      </Figure>
      <TextSection>
        <p>As an illustrator, Heinz Berger joined Roger Mayer in <span lang="de">Basel</span>, <span lang="fr">Publicis Groupe</span> in Paris and <span lang="de">Margarethe Hubauer</span> International Illustration from Hamburg. His illustrations were published by Rolling Stone Magazine and TransAtlantik among many others.</p>
      </TextSection>
    </PageSection>

    <PageSection content={{ heading: 'Solo exhibitions' }} >
      <TextSection>
        <ul>
          <li>1975: Paris, <span lang="fr">Michel Gillet</span></li>
          <li>1980: <span lang="de">Regensburg, Galerie Bäumler</span></li>
          <li>1993: Munich, <span lang="de">Loden-Frey</span></li>
          <li>1996: Munich, <span lang="de">Licht-Haus</span></li>
          <li>1997: Munich, <span lang="de">Galerie Im Haus 19 - Rainer Masset</span>, „Corners Or Lines“</li>
          <li>2009: Munich, <span lang="de">Galerie Westend</span>, „Yes - No (paintings)“</li>
        </ul>
      </TextSection>
    </PageSection>

    <PageSection content={{ heading: 'Group exhibitions' }} >
      <TextSection>
        <ul>
          <li>1985: Hamburg, <span lang="de">Margarethe Hubauer</span></li>
          <li>1988: Rosenheim, <span lang="de">Städtische Galerie</span>, „Out Of Munich“</li>
          <li>1991: Rosenheim, <span lang="de">Städtische Galerie</span>, „Out Of Munich II“</li>
          <li>1991: Munich, <span lang="de">Galerie U5</span></li>
          <li>1992: Munich, <span lang="de">Galerie U5, „Liebe-Teufel-Tod“</span></li>
          <li>1995: Munich, <span lang="de">Foraum</span>, „X-mas“</li>
          <li>1997: <span lang="de">Ingolstadt, Galerie NeuSEHland</span>, „Panama Rose“ - „New York Works“</li>
          <li>1998: <span lang="de">Ingolstadt, Galerie NeuSEHland</span>, exhibition and catalogue release</li>
          <li>2000: Munich, <span lang="de">Galerie Kemper</span>, „Small Is Beautiful“</li>
          <li>2001: Munich, <span lang="de">Atelier Berger, „Stadtmüller - Berger“</span></li>
          <li>2015: <span lang="de">Ingolstadt, Kunstraum</span>, „Kipp and Berger“</li>
          <li>2019: <span lang="de">Ingolstadt, Kunstraum</span></li>
        </ul>
      </TextSection>
    </PageSection>

    <PageSection content={{ heading: 'Contact' }} >
      <TextSection>
        <p>For any requests drop us an email. If you want to send regular mail please find the address in the <Link to="/imprint/">imprint</Link> section.</p>
        <a href="mailto:mail@heinzberger.info" className="b-text-section__button">Get in touch</a>
      </TextSection>
    </PageSection>

  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 768) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imagePnt058: file(relativePath: { eq: "painting-058.jpg" }) {
      ...fluidImage
    }
    imageIll004: file(relativePath: { eq: "illustration-004.jpg" }) {
      ...fluidImage
    }
  }
`;
